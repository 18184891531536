import { SET_PAGE_COLOR, SWITCH_LANGUAGE } from "./Types";
import { SWITCH_THEME } from "./Types";
import { SET_FORM_DATA } from "./Types";
import { REFRESH_REPORT_DATA } from "./Types";

const initialState = {
    global: {
        language: 'en',
        theme: 'dark',
        pageColor: {
            typography: {
                fontFamily: 'Gotham, sans-serif',
            },
            palette: {
                mode: 'dark',
                primary: {
                    main: "#009540",
                    contrastText: "#fff" //button text white instead of black to support dark theme
                },
                background: {
                    default: "#191917"
                },
                text: {
                    primary: "#fff",
                    secondary: "#fff",
                },
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "*": {
                                "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
                                "-moz-user-select": "text !important" /* Firefox 2+ */,
                                "-ms-user-select": "text !important" /* IE 10+ */,
                                "user-select": "text !important" /* Standard syntax */,
                            },
                        },
                    },
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            "*": {
                                "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
                                "-moz-user-select": "text !important" /* Firefox 2+ */,
                                "-ms-user-select": "text !important" /* IE 10+ */,
                                "user-select": "text !important" /* Standard syntax */,
                            },
                        },
                    },
                },
                MuiFilledInput: {
                    styleOverrides: {
                        root: {
                            "*": {
                                "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
                                "-moz-user-select": "text !important" /* Firefox 2+ */,
                                "-ms-user-select": "text !important" /* IE 10+ */,
                                "user-select": "text !important" /* Standard syntax */,
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        root: {
                            "*": {
                                "-webkit-user-select": "text !important" /* Chrome, Opera, Safari */,
                                "-moz-user-select": "text !important" /* Firefox 2+ */,
                                "-ms-user-select": "text !important" /* IE 10+ */,
                                "user-select": "text !important" /* Standard syntax */,
                            },
                        },
                    },
                },
            }
        }
    },

    functionCalled: false, //for refresh report data
}

const GlobalReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SWITCH_LANGUAGE: return {
            ...state,
            global: {
                ...state.global,
                language: action.language
            }
        }
        case SWITCH_THEME: return {
            ...state,
            global: {
                ...state.global,
                theme: action.theme
            }
        }
        case SET_FORM_DATA: return {
            ...state,
            global: {
                ...state.global,
                formData: action.formData
            }
        }
        case SET_PAGE_COLOR:
            return {
                ...state,
                global: {
                    ...state.global,
                    pageColor: action.pageColor
                }
            }

        case REFRESH_REPORT_DATA:
            return {
                ...state,
                functionCalled: action.refresh, // Update the state flag
            };

        default: return state
    }
}

export default GlobalReducer
