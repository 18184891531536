// @ts-nocheck

import '../../../../../index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReportGallery from '../ReportGallery/ReportGallery';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function DetailReport({ bookingData, activeNav }) {

    //data vars
    const [passData, setPassData] = useState([]);
    const [failData, setFailData] = useState([]);

    //ui vars
    const [value, setValue] = useState('1');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (bookingData.length > 0) {
            const passDataArray = bookingData.filter(item => item.status.toLowerCase() === "passed");
            const failDataArray = bookingData.filter(item => item.status.toLowerCase() === "failed");

            setPassData(passDataArray);
            setFailData(failDataArray);
        }
    }, [bookingData]); // Dependency array should be here


    return (
        <>
            <h1 style={{ marginTop: '1.5em' }}>{activeNav}</h1>

            <Box sx={{ width: '100%', typography: 'body1' }} className="mt-5">
                <TabContext value={value} className='mt-3'>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label={`All :  ${bookingData.length}`} value="1" icon={<FontAwesomeIcon icon="fa-solid fa-circle-minus" />} iconPosition="end" />
                            <Tab label={`Passed :  ${passData.length}`} value="2" icon={<FontAwesomeIcon icon="fa-solid fa-circle-check" />} iconPosition="end" />
                            <Tab label={`Failed :  ${failData.length}`} value="3" icon={<FontAwesomeIcon icon="fa-solid fa-circle-xmark" />} iconPosition="end" />
                        </TabList>
                    </Box>
                    <TabPanel value="1"><ReportGallery galleryData={bookingData} navName={activeNav} /></TabPanel>
                    <TabPanel value="2"><ReportGallery galleryData={passData} navName={activeNav} /></TabPanel>
                    <TabPanel value="3"><ReportGallery galleryData={failData} navName={activeNav} /></TabPanel>
                </TabContext>
            </Box>
        </>
    );
}

export default DetailReport;
