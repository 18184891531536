// @ts-nocheck

import '../../../../../index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function Welcome() {

    return (
        <>  
        <br />
            <h1 className='mt-5'>WELCOME TO PROTECTION</h1> <br />

            <Typography variant="body1" >
                Oh, hello there.
                <br /><br />
                Congratulations on protecting your money and sanity with a Property Check. That feeling rising up within you is peace of mind, and it's thanks to this Property Report Card - because the results of your Property Check lie within it.
            </Typography> <br />

            <Typography variant="h4" sx={{ color: 'rgb(0, 148, 64)' }}>
                HOW IT WORKS
            </Typography> <br />

            <Typography variant="body1" >
                We've carried out your Property Check using specialist equipment including thermal imaging cameras, boroscopes, moisture meters, and much, much more - including CheckTech, our own app we built with government-appointed developers - to show you the problems (or lack of them, hopefully) that you can't see. This Property Report Card and level of detail is available nowhere else on earth.
                <br /><br />
                Your Property Check Inspector has reported on everything they can see or observe at the time and date of your Property Check. So while we may be the best in the business, we can't predict the future - we can't be responsible for things that occur after the date on this Property Report Card.
            </Typography> <br />

            <Typography variant="h4" sx={{ color: 'rgb(0, 148, 64)' }}>
                OUR PASS AND FAIL SYSTEM
            </Typography> <br />

            <Typography variant="body1" >
                The Property Check operates on a very simple pass or fail system. If everything is perfect with something, it will pass. If there’s anything wrong with an item, be it major or minor, it will fail. We’ll always clarify what the issue is.
                <br /><br />
                Some sections of the Property Report Card show both passes and fails - we think it’s important for major categories such as A/C - and some will only show fails, so you can locate and rectify them faster. We don't want to clog up this report with 300 working plug sockets, for example.
            </Typography> <br />

            <Typography variant="h4" sx={{ color: 'rgb(0, 148, 64)' }}>
                DON'T JUST FOCUS ON WHAT IS IN HERE
            </Typography> <br />

            <Typography variant="body1" >
                An empty Property Report Card is an excellent Property Report Card, put it that way.
                <br /><br />
                We've inspected every inch of this property and <b>start from perfection</b>. So if you don't see something in the Property Report Card, it means it's passed. If you see one entry for a shower, for example, and it's cosmetic, it means it's passed every other check - no leaks, no damp, no poor water flow. That's incredibly positive.
                <br /><br />


                <b>If you need any help, call or visit us any time. We’re always here to chat on 800 CHECK ME and our Showroom is 213 Umm Suqeim Street. We love people coming in and talking about what we've found during your Property Check (we have great coffee and a PlayStation, too).</b>
                <br /><br />

                As always, please remember that this and all Property Report Cards are bound by our caring and very fair terms and conditions - https://ilove.propertycheck.me/terms-and-conditions
                <br /><br />
            </Typography> <br />

            <Typography variant="h6">
                Liam & Michael
                <br />
                PROPERTY CHECK CO-FOUNDERS
            </Typography>
            <br /> <br /> <br />
        </>
    );
}

export default Welcome;
