// @ts-nocheck

import '../../../../../index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function Overview({ bookingData }) {
    console.log(bookingData);

    const [doorImage, setDoorImage] = useState(null);
    const [issueCount, setIssueCount] = useState(0);
    const [address, setAddress] = useState(null);
    const [inspectionDate, setInspectionDate] = useState(null);
    const [propertyType, setPropertyType] = useState(null);
    const [propertySubtype, setPropertySubtype] = useState(null);
    const [clientName, setClientName] = useState(null);
    const [inspectorName, setInspectorName] = useState(null);
    const [bookingNumber, setBookingNumber] = useState(null);

    const setDataFuntion = () => {
        let count = 0;
        let image = null;
        let propAddress = null;
        let propInspectionDate = null;
        let propPropertyType = null;
        let propPropertySubtype = null;
        let propClientName = null;
        let propInspectorName = [];
        let propBookingNumber = null

        Object.keys(bookingData).forEach((key) => {
            count += bookingData[key]?.length || 0;

            //if data exist against key then run loop and extract inspector name
            if (bookingData[key]?.length > 0) {

                bookingData[key].forEach(item => {
                    const fullName = `${item.bookingissueUser.firstName} ${item.bookingissueUser.lastName}`;
                    if (!propInspectorName.includes(fullName)) {
                        propInspectorName.push(fullName);
                    }
                });
            }

            // Set image URL if not already set and if data exists
            if (!image && bookingData[key]?.length > 0) {
                image = bookingData[key][0].bookingissueBooking?.doorImage;
            }

            // Set address if not already set and if data exists
            if (!propAddress && bookingData[key]?.length > 0) {
                propAddress = bookingData[key][0].bookingissueBooking?.area + ' ' + bookingData[key][0].bookingissueBooking?.community;
            }

            // Set inspection date if not already set and if data exists
            if (!propInspectionDate && bookingData[key]?.length > 0) {
                propInspectionDate = bookingData[key][0].bookingissueBooking?.visitDate;
            }

            // Set client name if not already set and if data exists
            if (!propClientName && bookingData[key]?.length > 0) {
                propClientName = bookingData[key][0].bookingissueBooking?.clientName;
            }

            // Set property type if not already set and if data exists
            if (!propPropertyType && bookingData[key]?.length > 0) {
                propPropertyType = bookingData[key][0].bookingissueBooking?.bookingPropertytype?.name;
            }

            // Set property subtype if not already set and if data exists
            if (!propPropertySubtype && bookingData[key]?.length > 0) {
                propPropertySubtype = bookingData[key][0].bookingissueBooking?.bookingChildPropertytype?.name;
            }

            // Set booking number if not already set and if data exists
            if (!propBookingNumber && bookingData[key]?.length > 0) {
                propBookingNumber = bookingData[key][0].bookingId;
            }

        });

        setIssueCount(count);
        setDoorImage(image);
        setAddress(propAddress);
        setInspectionDate(propInspectionDate);
        setPropertyType(propPropertyType);
        setPropertySubtype(propPropertySubtype);
        setClientName(propClientName);
        setBookingNumber(propBookingNumber);
        if (propInspectorName.length > 0) {
            setInspectorName(propInspectorName.join(', '))
        }
    }

    useEffect(() => {
        if (bookingData && Object.keys(bookingData).length > 0) {
            setDataFuntion();
        }
    }, [bookingData]);

    return (
        <>
            <br />
            <h1 className='mt-5'>INSPECTION OVERVIEW</h1> <br />
            <div className="row mt-3">
                <div className="col-md-6">
                    <a
                        href={doorImage}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={doorImage}
                            alt="Image Not Available"
                            style={{ objectFit: 'cover', cursor: 'pointer', width: '100%' }} // Adjust styles as needed
                        />
                    </a>
                </div>
                <div className="col-md-6">
                    <div className="row">

                        <div className="col-6 d-flex align-items-center ps-4 mb-5">
                            <FontAwesomeIcon icon="fa-solid fa-location-dot" style={{ paddingRight: '1em' }} />
                            <Typography variant="body2">
                                <b>PROPERTY ADDRESS</b> <br />{address}
                            </Typography>
                        </div>

                        <div className="col-6 d-flex align-items-center mb-5">
                            <FontAwesomeIcon icon="fa-solid fa-calendar-days" style={{ paddingRight: '1em' }} />
                            <Typography variant="body2">
                                <b>INSPECTION DATE</b> <br />{inspectionDate}
                            </Typography>
                        </div>

                        <div className="col-6 d-flex align-items-center ps-4 mb-5">
                            <FontAwesomeIcon icon="fa-solid fa-house" style={{ paddingRight: '1em' }} />
                            <Typography variant="body2">
                                <b>PROPERTY TYPE</b> <br />{propertyType}
                            </Typography>
                        </div>

                        <div className="col-6 d-flex align-items-center mb-5">
                            <FontAwesomeIcon icon="fa-solid fa-building-columns" style={{ paddingRight: '1em' }} />
                            <Typography variant="body2">
                                <b>PROPERTY SUB-TYPE</b> <br />{propertySubtype}
                            </Typography>
                        </div>

                        <div className="col-6 d-flex align-items-center ps-4 mb-5">
                            <FontAwesomeIcon icon="fa-solid fa-person-chalkboard" style={{ paddingRight: '1em' }} />
                            <Typography variant="body2">
                                <b>INSPECTED BY</b> <br />{inspectorName}
                            </Typography>
                        </div>

                        <div className="col-6 d-flex align-items-center mb-5">
                            <FontAwesomeIcon icon="fa-solid fa-user-shield" style={{ paddingRight: '1em' }} />
                            <Typography variant="body2">
                                <b>INSPECTED FOR</b> <br />{clientName}
                            </Typography>
                        </div>

                        <div className="col-6 d-flex align-items-center ps-4 mb-5">
                            <FontAwesomeIcon icon="fa-solid fa-hashtag" style={{ paddingRight: '1em' }} />
                            <Typography variant="body2">
                                <b>INSPECTION NO</b> <br />{bookingNumber}
                            </Typography>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row my-5">
                <Typography variant="h4">
                    PROPERTY CHECK NOTICED  <mark>{issueCount}</mark> THINGS
                </Typography> <br /> <br /><br />

                <Typography variant="body1">
                    <b>Please note: your Overview section does not discuss the entire Property Check and detailed findings can be found in each section of the Property Report Card. This section only discusses the property on an overall basis and highlights what we see as important things to discuss.</b>
                </Typography><br /> <br /><br />

                <Typography variant="body1">
                    Overall the property is finished to a high standard, however there are a number of technical and cosmetic issues that need to be addressed.
                </Typography> <br /> <br /><br />

                <div>
                    {Object.entries(bookingData).map(([key, issues]) => (
                        <div key={key}>
                            <h2>{key}</h2>
                            <ul>
                                {issues.map(issue => (
                                    <li key={issue.id}>{issue.issueDescription}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
}

export default Overview;
