// @ts-nocheck

import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import '../../../../../../src/index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import Box from '@mui/material/Box';
import Popup from '../Popup/Popup';
import { getAll } from '../../../../../Services/GenericApiService';
import ReportSideNav from '../ReportSideNav/ReportSideNav';
import CssBaseline from '@mui/material/CssBaseline';
import DetailReport from '../DetailReport/DetailReport';
import { useSelector } from 'react-redux';
import Welcome from '../Welcome/Welcome';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Overview from '../Overview/Overview';
import { getUserData } from '../../../../../Services/LocalStorageService';
import { CLIENT_ROLE_ID } from '../../../../../Constants/appConstants';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function ReportMain({ clientEmail = null, visitDate = null, showLogout = true, onBackClick }) {

    const userdata = getUserData();

    const REFRESH_REPORT_CALLED = useSelector(
        (state: RootStateOrAny) => state.global.functionCalled,
    );

    // translation
    const { t } = useTranslation();

    // module(s) for api
    const bookingModule = 'booking';

    // data vars
    const [visitDataFromChild, setVisitDataFromChild] = useState({});
    const [bookingData, setBookingData] = useState([]);
    const [activeTitle, setActiveTitle] = useState('Welcome');
    const [categorizedData, setCategorizedData] = useState({});

    //ui vars
    const [isLoading, setIsLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(true);

    // Callback function to handle data from child
    const handleDataFromChild = (data) => {
        setShowPopup(false);
        setVisitDataFromChild(data);
    };

    // Callback function to handle data from child
    const handleMenuDataFromChild = (data) => {
        setActiveTitle(data);
    };

    const getBooking = () => {
        setIsLoading(true);
        getAll(`${bookingModule}/email/visitDate/${visitDataFromChild.clientEmail}/${visitDataFromChild.visitDate}`)
            .then((_res: any) => {
                setBookingData(_res);
                setCategorizedData(categorizeData(_res));
                setIsLoading(false);
            })
            .catch(_err => {
                console.log(_err);
                setIsLoading(false);
            })
    }

    const getBookingThroughParams = (_clientEmail, _visitDate) => {
        setIsLoading(true);
        getAll(`${bookingModule}/email/visitDate/${_clientEmail}/${_visitDate}`)
            .then((_res: any) => {
                setBookingData(_res);
                setCategorizedData(categorizeData(_res));
                setIsLoading(false);
            })
            .catch(_err => {
                console.log(_err);
                setIsLoading(false);
            })
    }

    const categorizeData = (data) => {
        const categorized = {};

        data.forEach(item => {
            const category = item.bookingissueIssuetype.issuetypeSubcategory.subcategoryCategory.name;

            if (!categorized[category]) {
                categorized[category] = [];
            }
            categorized[category].push(item);
        });

        return categorized;
    };

    useEffect(() => {

        if (visitDataFromChild.visitDate && userdata.roleId == CLIENT_ROLE_ID) {
            getBooking();
        }
    }, [visitDataFromChild]);

    useEffect(() => {
        if (REFRESH_REPORT_CALLED && userdata.roleId == CLIENT_ROLE_ID) {
            getBooking();
        }

    }, [REFRESH_REPORT_CALLED]);

    useEffect(() => {
        if (userdata.roleId != CLIENT_ROLE_ID && clientEmail != null && visitDate != null) {
            getBookingThroughParams(clientEmail, visitDate);
        }

    }, [clientEmail, visitDate]);

    return (
        <>

            <Box sx={{ display: 'flex' }}>

                <CssBaseline />

                <ReportSideNav onItemSelect={handleMenuDataFromChild} showLogout={showLogout} />

                {userdata.roleId == CLIENT_ROLE_ID &&
                    <AppBar position="fixed" className="bg-custom-gradient">
                        <Toolbar>
                            <Button
                                variant="contained"
                                onClick={() => setShowPopup(!showPopup)}
                                sx={{ ml: 'auto', display: { xs: 'none', sm: 'block' } }}
                            >
                                Show Other Reports
                            </Button>
                        </Toolbar>
                    </AppBar>
                }

                {userdata.roleId != CLIENT_ROLE_ID &&
                    <AppBar position="fixed" className="bg-custom-gradient">
                        <Toolbar>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    onBackClick();
                                }}
                                sx={{ ml: 'auto', display: { xs: 'none', sm: 'block' } }}
                            >
                                Back
                            </Button>
                        </Toolbar>
                    </AppBar>
                }

                {/* Content */}
                <Box component="main" sx={{ flexGrow: 2 }} mt={2}>

                    {showPopup && userdata.roleId == CLIENT_ROLE_ID &&
                        <Popup visitDate={handleDataFromChild} />
                    }

                    <div style={{paddingLeft:'4em'}}>
                        {/* For Welcome */}
                        {activeTitle === "Welcome" &&
                            <Welcome />
                        }


                        {/* For Overview */}
                        {bookingData.length != 0 && activeTitle === "Overview" &&
                            <Overview bookingData={categorizedData} />
                        }


                        {/* All Reports */}
                        {bookingData && activeTitle === "All Reports" &&
                            <Popup visitDate={handleDataFromChild} />
                        }

                        {/* For Issues */}
                        {bookingData && categorizedData[activeTitle] && activeTitle != "Welcome" && activeTitle != "Overview" &&
                            <DetailReport bookingData={categorizedData[activeTitle]} activeNav={activeTitle} />
                        }

                        {bookingData.length == 0 && activeTitle != "Welcome" &&
                            <>
                                <br />
                                <h1 className='mt-5'>No Data Available</h1> <br />
                            </>
                        }
                    </div>
                </Box>

            </Box>
        </>
    );
}

export default ReportMain;
