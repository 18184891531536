// @ts-nocheck

import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import Box from '@mui/material/Box';
import Tooltip from "@mui/material/Tooltip";
import { Button, Card, CardContent, IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CardActions from "@mui/material/CardActions";
import DataDisplayM from "../../../../Helpers/DataDisplayM/DataDisplayM";
import { log } from "../../../../../Services/LoggerService";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function BookingView({ data = null }) {
    // css for module
    const classes = useStyles();

    // translation
    const { t } = useTranslation();

    // ui handlers
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    // handle cancel event
    const handleCancelEvent = () => {
        handleClose();
    }

    const customIcon = L.divIcon({
        html: `<div style="color: red; font-size: 24px;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="#ff0000" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>
       
    </div>`,
        iconSize: [24, 24], // Adjust size if needed
        className: "custom-marker-icon",
    });

    const LocationMarker = ({ latitude, longitude }) => {
        return (
            <Marker position={[latitude, longitude]} icon={customIcon} />
        );
    };

    // hooks
    useEffect(() => {

    }, []);


    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <Tooltip title={t('common.view')}>
                    <IconButton aria-label="delete" size="small" onClick={handleOpen}>
                        <FontAwesomeIcon className="edit-btn" icon='eye' />
                    </IconButton>
                </Tooltip>
            </Box>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                onBackdropClick={handleCancelEvent}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>
                            <CardContent>
                                <div className="py-4 px-md-4 px-0">
                                    <h1 className="mb-3">{t('common.recordDetails')}</h1>
                                    <div className="row full-width">


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.clientName')} data={data.clientName} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.clientEmail')} data={data.clientEmail} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.clientContact')} data={data.clientContact} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.checkType')} data={data.bookingChecktype.name} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={"Re-Inspection"} data={data.addReInspection} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.propertyType')} data={data.bookingPropertytype.name} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.numberOfBedrooms')} data={data.numberOfBedrooms} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.area')} data={data.area} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.community')} data={data.community} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.unit')} data={data.unit} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.visitDate')} data={data.visitDate} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.visitSlot')} data={data.visitSlot} />
                                            }                                        </div>


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.inspector')} data={data?.bookingBookinginspector.length > 0 ? data?.bookingBookinginspector.map(item => item.bookinginspectorUser.email).join(' , ') : ""} />
                                            }                                        </div>

                                        {data.doorImage &&

                                            <>
                                                <div className="col-md-6">
                                                    {data &&
                                                        <>
                                                            <div className="my-3">
                                                                <Typography variant="subtitle1" component="div" className="text-muted">
                                                                    {t('booking.doorImage')}
                                                                </Typography>
                                                                <a href={data.doorImage} target='black'>{data.doorImage}</a>

                                                            </div>
                                                        </>
                                                    }
                                                </div>

                                                <div className="col-md-6">
                                                    {data &&
                                                        <DataDisplayM label={t('booking.doorImageTimeStamp')} data={data?.doorImageTimeStamp} />
                                                    }
                                                </div>
                                            </>
                                        }


                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.longitude')} data={data?.longitude} />
                                            }                                        </div>

                                        <div className="col-md-6">
                                            {data &&
                                                <DataDisplayM label={t('booking.latitude')} data={data?.latitude} />
                                            }                                        </div>

                                        {data?.longitude != null && data?.latitude != null &&
                                            <div className="col-md-12">
                                                <div className="container-fluid">
                                                    <MapContainer
                                                        center={[data.latitude, data.longitude]}
                                                        zoom={19}
                                                        style={{ height: "50em", width: "100%" }}
                                                        dragging={false}
                                                        zoomControl={false}
                                                        scrollWheelZoom={false}
                                                        doubleClickZoom={false}
                                                    >
                                                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                                        <LocationMarker latitude={data.latitude} longitude={data.longitude} />
                                                    </MapContainer>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    <div className="row full-width">
                                        <h3 className="my-3">Booking Room Type Details</h3>

                                        {data?.bookingBookingroomtype.map(item => (
                                            <React.Fragment key={item.id}>
                                                <div className="col-md-6">
                                                    <DataDisplayM label={"Room Type Parent"} data={item?.bookingroomtypeRoomtype?.name} />
                                                </div>
                                                <div className="col-md-6">
                                                    <DataDisplayM label={"Room Type Child"} data={item?.bookingroomtypeChildRoomtype?.name} />
                                                </div>
                                            </React.Fragment>
                                        ))}

                                    </div>
                                </div>
                            </CardContent>
                            <CardActions>
                                <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                                    {/* Cancel Button */}
                                    <Button variant="outlined"
                                        onClick={handleCancelEvent}>
                                        {t('common.cancel')}
                                    </Button>

                                    &nbsp;&nbsp;
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default BookingView;
