
/** Maps user against their IDs */
const user = {
    admin: 1,
    inspector: 2,
    client: 3
}

export const accessGroups: any = {
    // input: {
    //     /** Access Group to view All teams shifts sites */
    //     edit: [user.superAdmin, user.admin, user.siteManager]
    // },
    // teamShiftSites: {
    //     /** Access Group to Edit Input Data */
    //     all: [user.superAdmin, user.admin],
    // },
    // user: {
    //     /** Access Group to edit other users */
    //     edit: [user.superAdmin, user.admin]
    // },
    routes: {
        dashboard: [user.admin, user.inspector],
        user: [user.admin],
        inspectorMannagement: [user.admin],
        role: [user.admin],
        leads: [user.admin],
        mail_server: [user.admin],
        emailtemplate: [user.admin],
        whatsapptemplate: [user.admin],
        environment_variables: [user.admin],
        custombookingform: [user.admin],
        custombookingformdata: [user.admin],
        checktype: [user.admin],
        propertytype: [user.admin],
        roomtype: [user.admin],
        booking: [user.admin],
        categoryIssue: [user.admin],
        inspectionTemplate: [user.admin],
        report: [user.client],
        bookingReports: [user.admin, user.inspector]
    }
}
