// @ts-nocheck

import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import '../../../../../index.scss';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { getById } from '../../../../../Services/GenericApiService';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { DialogContentText, Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import Box from '@mui/material/Box';
import LoadingButton from "@mui/lab/LoadingButton";
import { getUserData } from '../../../../../Services/LocalStorageService';
import { useNavigate } from "react-router-dom";

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function Popup({ visitDate }) {

    // translation
    const { t } = useTranslation();
    const userdata = getUserData();

    // Router object
    const navigate = useNavigate();

    // module(s) for api
    const bookingModule = 'booking';

    // data vars
    const [selectedVisitDate, setSelectedVisitDate] = useState('');
    const [allVisitDate, setAllVisitDate] = useState([]);

    //ui controls
    const [open, setOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    //send booking date to parent
    const sendDataToParent = () => {
        visitDate({ "visitDate": selectedVisitDate, "clientEmail": userdata.email });
        setOpen(false);
    };

    const handleSelectChange = (event) => {
        setSelectedVisitDate(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = () => {
        navigateToComponent('/logout');
    };

    const navigateToComponent = (url) => {
        navigate(url)
    }

    // get all visit date
    const getAllVisitDate = () => {
        setIsLoading(true);
        getById(bookingModule + '/report/visitDate', userdata.email)
            .then((_res: any) => {
                setAllVisitDate(_res);
                setIsLoading(false);
            })
            .catch(_err => {
                log(_err);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getAllVisitDate();
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>

            {/* modal apper which will take booking date as input to populate field */}
            <>
                {!isLoading &&
                    <Dialog
                        open={open}
                        fullWidth
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Please Select The Visit Date
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {/* Dropdown Field */}
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="select-label">Select an Option</InputLabel>
                                    {allVisitDate.length > 0 && (
                                        <Select
                                            labelId="select-label"
                                            id="select-dropdown"
                                            value={selectedVisitDate}
                                            label="Select an Option"
                                            onChange={handleSelectChange}
                                        >
                                            {allVisitDate.map((el: any) => (
                                                <MenuItem value={el.visitDate} key={el.visitDate}>
                                                    {el.visitDate}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                    {allVisitDate.length === 0 && (
                                        <>
                                            <Select
                                                labelId="select-label"
                                                id="select-dropdown"
                                                disabled
                                            >
                                                <MenuItem value={''}>No Booking Available</MenuItem>
                                            </Select>
                                            <FormHelperText>No Booking Available</FormHelperText>
                                        </>
                                    )}
                                </FormControl>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

                            <div>
                                <LoadingButton
                                    loading={isLoading}
                                    onClick={handleLogout}
                                    variant="contained"
                                    className='ms-3'
                                    style={{ backgroundColor: 'red' }}
                                >
                                    Logout
                                </LoadingButton>
                            </div>


                            {allVisitDate.length > 0 &&
                                <div>
                                    <LoadingButton
                                        onClick={sendDataToParent}
                                        loading={isLoading}
                                        variant="contained"
                                        className='me-3'
                                    >
                                        OK
                                    </LoadingButton>
                                </div>
                            }

                        </DialogActions>
                    </Dialog>
                }
            </>
        </Box>
    );
}

export default Popup;
