// @ts-nocheck

import React, { useEffect } from 'react';
import { useStyles } from './Styles';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom'
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { collapse } from "./Actions";
import { expand } from "../Header/Actions"
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

const drawerWidth = 240;

interface menuItem {
    title: '',
    url: '',
    icon: ''
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

function Sider() {
    // Set Theme
    const theme = useTheme();

    // Css for module
    const classes = useStyles();

    // Router object
    const navigate = useNavigate();

    // current location
    const location = useLocation();

    // Redux
    const sider = useSelector(
        (state: RootStateOrAny) => state.sider,
    );

    const header = useSelector(
        (state: RootStateOrAny) => state.header,
    );

    // Dispatcher
    const dispatch = useDispatch()

    // UI vars
    const [open, setOpen] = React.useState(sider.state.isExpanded);

    // Hooks
    useEffect(() => {
        setOpen(sider.state.isExpanded);
    }, [sider]);

    useEffect(() => {
        // setOpen(!header.state.isExpanded)
    }, [header]);


    // UI toggles
    const handleDrawerClose = () => {
        dispatch(collapse()); //sider state collapses
        dispatch(expand()); //header state expands
        // setOpen(false);
    };

    const navigateToComponent = (url) => {
        navigate(url)
    }


    // Menu Items
    const menuItems: any = [
        {
            title: 'Administration',
            url: '/',
            icon: 'user-tie',
            showDivider: true,
            isHeading: true
        },
        {
            title: 'Dashboard',
            url: '/dashboard',
            icon: 'chart-line',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Roles',
            url: '/role',
            icon: 'user-lock',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Users',
            url: '/user',
            icon: 'user-plus',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Inspector Dashboard',
            url: '/inspectorMannagement',
            icon: 'user-tie',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Partners',
            url: '/',
            icon: 'handshake',
            showDivider: true,
            isHeading: true
        },
        {
            title: 'Forms Branding',
            url: '/custombookingform',
            icon: 'palette',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Inspection Requests',
            url: '/custombookingformdata',
            icon: 'list-check',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Leads',
            url: '/leads',
            icon: 'street-view',
            showDivider: true,
            isHeading: false
        },
        {
            title: 'Bookings',
            url: '/booking',
            icon: 'calendar-check',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Booking Reports',
            url: '/bookingReports',
            icon: 'folder-open',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Configurations',
            url: '/',
            icon: 'gears',
            showDivider: true,
            isHeading: true
        },
        {
            title: 'Mail Server',
            url: '/mail_server',
            icon: 'cloud',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Integrations',
            url: '/environment_variables',
            icon: 'laptop-code',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Check Types',
            url: '/checktype',
            icon: 'building-circle-check',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Property Types',
            url: '/propertytype',
            icon: 'house-flag',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Room Types',
            url: '/roomtype',
            icon: 'bed',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Category & Issue',
            url: '/categoryIssue',
            icon: 'fa-layer-group',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Templates',
            url: '/',
            icon: 'file-lines',
            showDivider: true,
            isHeading: true
        },
        {
            title: 'Inspection Template',
            url: '/inspectionTemplate',
            icon: 'fa-solid fa-house-circle-check',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Email Template',
            url: '/emailtemplate',
            icon: 'envelope',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Whatsapp Template',
            url: '/whatsapptemplate',
            icon: 'comment-dots',
            showDivider: false,
            isHeading: false
        },
        {
            title: 'Logout',
            url: '/logout',
            icon: 'right-from-bracket',
            showDivider: true,
            isHeading: false
        },
        // %TEMPLATE_MENU_ITEMS%


    ];

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <svg width="161" height="50" viewBox="0 0 161 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3207_19658)">
                        <path d="M0.34491 29.59L127.295 29.59V-3.05176e-05L0.34491 -3.05176e-05V29.59Z" fill="#009540"></path>
                        <path d="M8.50488 8.02002H15.0349C18.8949 8.02002 21.5249 9.74002 21.5249 13.18C21.5249 16.68 18.8449 18.55 14.9349 18.55H13.3549V22.53H8.50488V8.02002ZM14.6649 15.13C15.9049 15.13 16.7349 14.57 16.7349 13.53C16.7349 12.47 15.9649 11.93 14.6849 11.93H13.3549V15.17L14.6649 15.13Z" fill="white"></path>
                        <path d="M22.0449 8.02002H29.1349C31.7049 8.02002 33.3449 8.66004 34.3649 9.67004C34.8124 10.1219 35.1609 10.6621 35.3882 11.256C35.6154 11.85 35.7165 12.4848 35.6849 13.12C35.7065 14.0612 35.4456 14.9872 34.9358 15.7786C34.426 16.57 33.6908 17.1905 32.8249 17.56L36.2049 22.53H30.6749L27.9349 18.38H26.9349V22.53H22.0849L22.0449 8.02002ZM28.9949 14.92C30.1549 14.92 30.8549 14.4 30.8549 13.51C30.8549 12.58 30.1349 12.12 29.0149 12.12H26.8949V14.96L28.9949 14.92Z" fill="white"></path>
                        <path d="M36.0254 15.31C36.1275 13.2783 37.0065 11.3636 38.4806 9.9617C39.9547 8.55985 41.9112 7.77808 43.9454 7.77808C45.9796 7.77808 47.9361 8.55985 49.4102 9.9617C50.8843 11.3636 51.7633 13.2783 51.8654 15.31C51.7633 17.3416 50.8843 19.2564 49.4102 20.6582C47.9361 22.0601 45.9796 22.8419 43.9454 22.8419C41.9112 22.8419 39.9547 22.0601 38.4806 20.6582C37.0065 19.2564 36.1275 17.3416 36.0254 15.31ZM46.9654 15.31C46.9883 14.8971 46.9283 14.4838 46.789 14.0945C46.6498 13.7051 46.434 13.3475 46.1544 13.0428C45.8749 12.7381 45.5372 12.4924 45.1613 12.3202C44.7853 12.1479 44.3787 12.0527 43.9654 12.04C43.5539 12.0474 43.1482 12.1378 42.7724 12.3056C42.3966 12.4735 42.0586 12.7155 41.7785 13.017C41.4985 13.3186 41.2821 13.6735 41.1424 14.0606C41.0027 14.4477 40.9425 14.859 40.9654 15.27C40.9425 15.6833 41.0024 16.0971 41.1416 16.4871C41.2808 16.877 41.4964 17.2352 41.7758 17.5407C42.0552 17.8462 42.3928 18.0929 42.7688 18.2662C43.1448 18.4395 43.5516 18.5359 43.9654 18.55C44.3778 18.5426 44.7844 18.4519 45.1609 18.2834C45.5373 18.1149 45.8758 17.8721 46.156 17.5695C46.4363 17.2668 46.6524 16.9107 46.7915 16.5224C46.9306 16.1341 46.9897 15.7217 46.9654 15.31Z" fill="white"></path>
                        <path d="M52.6753 8.02002H59.2053C63.0653 8.02002 65.6953 9.74002 65.6953 13.18C65.6953 16.68 63.0253 18.55 59.1053 18.55H57.5053V22.53H52.6553L52.6753 8.02002ZM58.8353 15.13C60.0753 15.13 60.9053 14.57 60.9053 13.53C60.9053 12.47 60.1353 11.93 58.9053 11.93H57.5053V15.17L58.8353 15.13Z" fill="white"></path>
                        <path d="M66.2148 8.02002H78.5948V12.02H70.9849V13.49H78.0749V16.99H70.9849V18.53H78.6949V22.53H66.2148V8.02002Z" fill="white"></path>
                        <path d="M79.5049 8.02002H86.5949C89.1649 8.02002 90.8049 8.66004 91.8249 9.67004C92.2737 10.1214 92.6237 10.6613 92.8526 11.2552C93.0816 11.8491 93.1845 12.4842 93.1549 13.12C93.177 14.0626 92.9152 14.99 92.4034 15.7818C91.8915 16.5736 91.1534 17.1932 90.2849 17.56L93.6649 22.49H88.1349L85.3949 18.34H84.3949V22.53H79.5049V8.02002ZM86.4549 14.92C87.6149 14.92 88.3149 14.4 88.3149 13.51C88.3149 12.58 87.5949 12.12 86.4749 12.12H84.3549V14.96L86.4549 14.92Z" fill="white"></path>
                        <path d="M97.5053 12.12H93.2354V8.02002H106.625V12.12H102.355V22.53H97.5053V12.12Z" fill="white"></path>
                        <path d="M111.505 17.39L106.035 8.02002H111.505L113.995 12.78L116.505 8.02002H121.875L116.395 17.33V22.53H111.505V17.39Z" fill="white"></path>
                        <path d="M8.17516 41.98C8.16535 40.9713 8.35912 39.9709 8.74489 39.0389C9.13066 38.1068 9.7005 37.2621 10.4203 36.5554C11.1401 35.8486 11.9951 35.2944 12.9341 34.9257C13.8731 34.5571 14.8768 34.3817 15.8852 34.41C17.2243 34.3553 18.5516 34.6802 19.714 35.3472C20.8764 36.0142 21.8267 36.9962 22.4552 38.18L18.5051 40.53C18.292 40.008 17.9277 39.5616 17.4592 39.248C16.9906 38.9344 16.439 38.768 15.8751 38.77C15.4854 38.791 15.1039 38.8909 14.7538 39.0634C14.4036 39.2359 14.092 39.4776 13.8378 39.7738C13.5837 40.0701 13.3921 40.4147 13.2748 40.787C13.1575 41.1593 13.1168 41.5515 13.1551 41.94V41.99C13.1551 43.87 14.2751 45.2 15.8751 45.2C16.4589 45.1937 17.0281 45.0171 17.5131 44.6921C17.998 44.367 18.3775 43.9075 18.6052 43.37L22.6052 45.63C21.7786 47.0989 20.4924 48.2556 18.9443 48.9221C17.3962 49.5886 15.672 49.7281 14.0369 49.319C12.4018 48.91 10.9464 47.9751 9.89455 46.6581C8.84268 45.3411 8.25262 43.715 8.21514 42.03L8.17516 41.98Z" fill="white"></path>
                        <path d="M22.9746 34.6799H27.8246V39.7999H32.2646V34.6799H37.1146V49.1899H32.2646V43.9899H27.8246V49.1899H22.9746V34.6799Z" fill="white"></path>
                        <path d="M38.2549 34.6799H50.6349V38.6799H43.0249V40.1499H50.1249V43.6599H43.0249V45.2299H50.7449V49.2299H38.2549V34.6799Z" fill="white"></path>
                        <path d="M50.9945 41.98C50.9847 40.9713 51.1785 39.971 51.5643 39.0389C51.95 38.1068 52.5199 37.2621 53.2397 36.5554C53.9595 35.8487 54.8144 35.2944 55.7534 34.9258C56.6924 34.5571 57.6962 34.3817 58.7045 34.41C60.0451 34.3546 61.374 34.6791 62.5382 35.3461C63.7024 36.0131 64.6544 36.9955 65.2845 38.18L61.2845 40.52C61.0725 39.9972 60.7085 39.5501 60.2396 39.2363C59.7708 38.9226 59.2186 38.7567 58.6545 38.76C58.2647 38.7811 57.8833 38.8809 57.5331 39.0534C57.183 39.2259 56.8714 39.4676 56.6172 39.7639C56.363 40.0601 56.1715 40.4048 56.0542 40.777C55.9368 41.1493 55.8961 41.5415 55.9345 41.93V41.98C55.9345 43.86 57.0545 45.19 58.6545 45.19C59.24 45.1856 59.8114 45.01 60.2982 44.6848C60.7851 44.3597 61.1662 43.8991 61.3945 43.36L65.3945 45.62C64.568 47.0889 63.2818 48.2456 61.7337 48.9121C60.1856 49.5787 58.4614 49.7181 56.8263 49.3091C55.1912 48.9 53.7358 47.9651 52.6839 46.6481C51.6321 45.3311 51.042 43.7051 51.0045 42.02L50.9945 41.98Z" fill="white"></path>
                        <path d="M65.7949 34.6799H70.6549V40.1099L74.7749 34.6799H80.4149L75.3549 40.9799L80.5249 49.1899H74.7349L71.8949 44.5899L70.6549 46.0999V49.1899H65.7949V34.6799Z" fill="white"></path>
                        <path d="M1.10449 41.85H127.284" stroke="white" stroke-width="1.48" stroke-miterlimit="10"></path>
                        <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                        <path d="M160.665 0H131.075V29.59H160.665V0Z" fill="#009540"></path>
                        <path d="M135.705 16.15L142.145 22.59L156.045 8.69995" stroke="white" stroke-width="1.58" stroke-miterlimit="10"></path>
                    </g>
                    <defs>
                        <clipPath id="clip0_3207_19658">
                            <rect width="160.33" height="49.5" fill="white" transform="translate(0.334961)"></rect>
                        </clipPath>
                    </defs>
                </svg>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <List className={'pt-0'}>
                {menuItems.map((item: any, index: any) => (
                    <Tooltip title={item.title} placement="right">
                        <>
                            {/* If menu item */}
                            {!item.isHeading &&
                                <Tooltip title={item.title} placement="right">

                                    <ListItemButton
                                        className={location.pathname == item.url ? 'bg-custom-secondary-faded' : ''}
                                        onClick={() => navigateToComponent(item.url)}
                                        key={item.title}
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? 'initial' : 'center',
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : 'auto',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <FontAwesomeIcon icon={item.icon}/>

                                        </ListItemIcon>
                                        <ListItemText primary={item.title} sx={{opacity: open ? 1 : 0}}/>
                                    </ListItemButton>
                                </Tooltip>

                            }

                            {/* If Heading */}
                            {item.isHeading &&
                                <ListItemButton
                                    className={'bg-custom-primary-faded '}
                                    key={item.title}
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FontAwesomeIcon icon={item.icon}/>

                                    </ListItemIcon>
                                    <ListItemText primary={item.title} sx={{opacity: open ? 1 : 0}}/>
                                </ListItemButton>
                            }
                            {item.showDivider &&
                                <Divider/>
                            }
                        </>
                    </Tooltip>
                ))}
            </List>
        </Drawer>
    );
}

export default Sider;
