// @ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import '../../../../index.scss';
import Header from "../../../Layout/Header/Header";
import Sider from "../../../Layout/Sider/Sider";
import { getAll } from "../../../../Services/GenericApiService";
import { useTranslation } from "react-i18next";
import { log } from "../../../../Services/LoggerService";
import { formatData } from "../../../../Services/DataFormatService";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MUIDataTable from "mui-datatables";
import { IconButton, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import Tooltip from '@mui/material/Tooltip';
import ReportMain from '../Report/ReportMain/ReportMain';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function BookingReport() {

    // translation
    const { t } = useTranslation();

    // child ref for alert
    const notifications = useRef();

    // module(s) for api
    const moduleMain = 'booking';

    // data vars
    const [allData, setAllData] = useState([]);
    const [clientEmail, setClientEmail] = useState(null);
    const [visitDate, setVisitDate] = useState(null);

    let columns = [
        { name: "Booking Id", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Client Email", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Check Type", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Property Type", options: { filter: false, filterOptions: { fullWidth: true } } },
        { name: "Visit Slot", options: { filter: true, filterType: 'checkbox', filterOptions: { fullWidth: true } } },
        { name: "Area", options: { filter: true, filterOptions: { fullWidth: true } } },
        { name: "Visit Date", options: { filter: false, filterOptions: { fullWidth: true } } },

        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                setCellHeaderProps: () => { return { align: "right" } },
                setCellProps: () => ({
                    align: "right"
                }),
                customBodyRenderLite: (dataIndex: any, rowIndex: any) => {
                    return (
                        <div className='d-inline-flex'>
                            <div className="px-2">
                                <Tooltip title={'Report'}>
                                    <IconButton aria-label="delete" size="small" onClick={() => {
                                        onEditClick(dataIndex, rowIndex)
                                    }}>
                                        <FontAwesomeIcon icon="fa-solid fa-file-shield" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    )
                        ;
                }
            }
        }
    ];
    const [tableData, setTableData] = React.useState([]);

    // ui controls
    const [loading, setLoading] = React.useState(true);
    const [showReport, setShowReport] = React.useState(false);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);

    // fetch dependent data
    const fetch = () => {
        setShowReport(false);
        setTableData([]);  // reset table data
        setLoading(true); // show loader
        getAll(moduleMain + '/complete/report', currentPage, pageSize)
            .then((_res: any) => {
                // set all data
                setAllData(_res.rows)

                // set total count
                setTotalRowCount(_res.count);

                const rows: any = [];
                _res.rows.forEach((_record: any) => {
                    rows.push(formatData([_record?.id, _record?.clientEmail, _record?.bookingChecktype?.name, _record?.bookingPropertytype?.name, _record?.visitSlot, _record?.area, _record?.visitDate, _record?.bookingBookinginspector.length > 0 ? _record?.bookingBookinginspector.map(item => item.bookinginspectorUser.email).join(' , ') : ""]))
                });

                setTableData(rows);
                setLoading(false); // hide loader
            })
            .catch(_err => {
                log(_err)
                setLoading(false); // hide loader
            })
    }

    useEffect(() => {
        fetch();
    }, [currentPage, pageSize])

    // event handlers
    const onEditClick = (_dataIndex: any, _rowIndex: any) => {
        setClientEmail(allData[_dataIndex].clientEmail);
        setVisitDate(allData[_dataIndex].visitDate);
        setShowReport(true);
    }

    const resetEddit = () => {
        setShowReport(false);
    }

    let options = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: "textField",
        responsive: "simple",
        selectableRowsHideCheckboxes: true,
        sort: true,
        onChangePage: (currentPage) => { setCurrentPage(currentPage); },
        onChangeRowsPerPage: (numberOfRows) => { setPageSize(numberOfRows); },
        page: currentPage,
        count: totalRowCount,
        serverSide: true,
        loading: true,
        rowsPerPageOptions: [25, 50, 75, 100],
        rowsPerPage: pageSize,
        textLabels: {
            body: {
                noMatch: loading ?
                    <Box>
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                        <Skeleton className="my-4" />
                    </Box> :
                    'Sorry, there is no matching data to display',
            },
        },
        onTableChange: (action: any, state: any) => {
        }
    };

    if (tableData.length === 0) {
        options = {
            textLabels: {
                body: {
                    noMatch: loading ?
                        <Box>
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                            <Skeleton className="my-4" />
                        </Box> :
                        'Sorry, there is no matching data to display',
                },
            },
        };
    }

    return (
        <>
            {!showReport &&

                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />

                    {/* Header */}
                    <Header />

                    {/* Sider */}
                    <Sider />

                    {/* Content */}
                    <Box component="main" sx={{ flexGrow: 2, p: 3 }} mt={10}>
                        <div className="px-1 px-md-5">

                            {/* Upper Section */}
                            <div className="row">
                                <div className="col-md-10">
                                    <h2 className="mt-3">
                                        Booking Reports
                                    </h2>
                                </div>
                            </div>

                            <div className="mt-5">
                                <Paper elevation={12}>
                                    <MUIDataTable
                                        title={t('common.allRecords')}
                                        data={tableData}
                                        columns={columns}
                                        // @ts-ignore
                                        options={options}
                                    />
                                </Paper>
                            </div>


                        </div>

                    </Box>
                </Box>

            }

            {showReport && clientEmail && visitDate &&
                <ReportMain clientEmail={clientEmail} visitDate={visitDate} showLogout={false} onBackClick={resetEddit}/>
            }
        </>
    );
}

export default BookingReport;