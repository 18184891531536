// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Import components
import Landing from "../Components/Screens/Landing/Landing";
import Login from "../Components/Screens/Authentication/Login/Login";
import Register from "../Components/Screens/Authentication/Register/Register";
import Home from "../Components/Screens/Dashboard/Home/Home"
import Logout from "../Components/Screens/Authentication/Logout/Logout";
import { RootStateOrAny, useSelector } from "react-redux";
import { useTranslation, Trans } from 'react-i18next';
import { AuthGuard } from "./Guards/AuthGuard/AuthGuard";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PropertyTypeMain from '../Components/Screens/Dashboard/PropertyType/PropertyTypeMain/PropertyTypeMain';
import RoomTypeMain from '../Components/Screens/Dashboard/RoomType/RoomTypeMain/RoomTypeMain';
import CheckTypeMain from '../Components/Screens/Dashboard/CheckType/CheckTypeMain/CheckTypeMain';
import RoleMain from "../Components/Screens/Dashboard/Role/RoleMain/RoleMain";
import UserMain from "../Components/Screens/Dashboard/User/UserMain/UserMain";
import CustombookingformMain
    from "../Components/Screens/Dashboard/Custombookingform/CustombookingformMain/CustombookingformMain";
import CustombookingformdataMain
    from "../Components/Screens/Dashboard/Custombookingformdata/CustombookingformdataMain/CustombookingformdataMain";
import WhiteLabelledFormMain from "../Components/Screens/WhiteLabelledForm/WhiteLabelledFormMain/WhiteLabelledFormMain";
import MailServerMain from '../Components/Screens/Dashboard/MailServer/MailServerMain/MailServerMain';
import EnvironmentVariablesMain from '../Components/Screens/Dashboard/EnvironmentVariables/EnvironmentVariablesMain/EnvironmentVariablesMain';
import Emailtemplates from '../Components/Screens/Dashboard/EmailTemplates/Emailtemplates';
import WhatsappTemplates from '../Components/Screens/Dashboard/WhatsappTemplates/WhatsappTemplates';
import LeadsMain from '../Components/Screens/Dashboard/Leads/LeadsMain/LeadsMain';
import Booked from "../Components/Screens/Booked/Booked";
import BookingMain from '../Components/Screens/Dashboard/Booking/BookingMain/BookingMain';
import InspectorDashboardNav from '../Components/Screens/Dashboard/InspectorDashboard/InspectorDashboardNav/InspectorDashboardNav';
import CategoryAndIssueNav from '../Components/Screens/Dashboard/CategoryAndIssue/CategoryAndIssueNav/CategoryAndIssueNav';
import InspectionTemplateMain from '../Components/Screens/Dashboard/InspectionTemplate/InspectionTemplateMain/InspectionTemplateMain';
import ReportMain from '../Components/Screens/Dashboard/Report/ReportMain/ReportMain';
import BookingReport from '../Components/Screens/Dashboard/BookingReport/BookingReport';

// %TEMPLATE_IMPORT_COMPONENT%


function Router() {
    const { t, i18n } = useTranslation();

    // get current language
    // const language = useSelector(
    //     (state: RootStateOrAny) => state.global.global.language,
    // );

    // switch language here
    // i18n.changeLanguage('en')

    // get current theme
    const theme = useSelector(
        (state: RootStateOrAny) => state.global.global.theme,
    );

    const pageColor = useSelector(
        (state: RootStateOrAny) => state.global.global.pageColor,
    );

    const darkTheme = createTheme(pageColor);

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <BrowserRouter>
                {/*<Header/>*/}
                <Routes>
                    <Route path="/landing" element={<Landing />} />
                    <Route path="/booked" element={<Booked />} />
                    <Route path="/" element={<Landing />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/dashboard" element={<AuthGuard route={'dashboard'}/>}>
                        <Route path="/dashboard" element={<Home />} />
                    </Route>

                    <Route path="/role" element={<AuthGuard route={'role'}/>}>
                        <Route path="/role" element={<RoleMain />} />
                    </Route>

                    <Route path="/user" element={<AuthGuard route={'user'} />}>
                        <Route path="/user" element={<UserMain />} />
                    </Route>

                    <Route path="/inspectorMannagement" element={<AuthGuard route={'inspectorMannagement'} />}>
                        <Route path="/inspectorMannagement" element={<InspectorDashboardNav />} />
                    </Route>

                    <Route path="/leads" element={<AuthGuard route={'leads'} />}>
                        <Route path="/leads" element={<LeadsMain />} />
                    </Route>

                    <Route path="/partners/:url" element={<WhiteLabelledFormMain />} />

                    <Route path="/mail_server" element={<AuthGuard route={'mail_server'} />}>
                        <Route path="/mail_server" element={<MailServerMain />} />
                    </Route>

                    <Route path="/emailtemplate" element={<AuthGuard route={'emailtemplate'} />}>
                        <Route path="/emailtemplate" element={<Emailtemplates />} />
                    </Route>

                    <Route path="/whatsapptemplate" element={<AuthGuard route={'whatsapptemplate'} />}>
                        <Route path="/whatsapptemplate" element={<WhatsappTemplates />} />
                    </Route>

                    <Route path="/environment_variables" element={<AuthGuard route={'environment_variables'} />}>
                        <Route path="/environment_variables" element={<EnvironmentVariablesMain />} />
                    </Route>

                    <Route path="/custombookingform" element={<AuthGuard route={'custombookingform'} />}>
                        <Route path="/custombookingform" element={<CustombookingformMain />} />
                    </Route>

                    <Route path="/custombookingformdata" element={<AuthGuard route={'custombookingformdata'} />}>
                        <Route path="/custombookingformdata" element={<CustombookingformdataMain />} />
                    </Route>

                    <Route path="/checktype" element={<AuthGuard route={'checktype'} />}>
                        <Route path="/checktype" element={<CheckTypeMain />} />
                    </Route>

                    <Route path="/propertytype" element={<AuthGuard route={'propertytype'} />}>
                        <Route path="/propertytype" element={<PropertyTypeMain />} />
                    </Route>

                    <Route path="/roomtype" element={<AuthGuard route={'roomtype'} />}>
                        <Route path="/roomtype" element={<RoomTypeMain />} />
                    </Route>

                    <Route path="/booking" element={<AuthGuard route={'booking'} />}>
                        <Route path="/booking" element={<BookingMain />} />
                    </Route>

                    <Route path="/categoryIssue" element={<AuthGuard route={'categoryIssue'} />}>
                        <Route path="/categoryIssue" element={<CategoryAndIssueNav />} />
                    </Route>

                    <Route path="/inspectionTemplate" element={<AuthGuard route={'inspectionTemplate'} />}>
                        <Route path="/inspectionTemplate" element={<InspectionTemplateMain />} />
                    </Route>

                    <Route path="/report" element={<AuthGuard route={'report'} />}>
                        <Route path="/report" element={<ReportMain />} />
                    </Route>


                    <Route path="/bookingReports" element={<AuthGuard route={'bookingReports'} />}>
                        <Route path="/bookingReports" element={<BookingReport />} />
                    </Route>
                    {/* %TEMPLATE_INSERT_ROUTE%  */}


                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default Router;
