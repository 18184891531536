// @ts-nocheck

import '../../../../../index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import UpdateViewModal from '../UpdateViewModal/UpdateViewModal';
import { getUserData } from '../../../../../Services/LocalStorageService';
import { CLIENT_ROLE_ID } from '../../../../../Constants/appConstants';
import { useForm } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function ReportGallery({ galleryData, navName }) {

    const userdata = getUserData();

    //data handlers
    const [dataToEdit, setDataToEdit] = useState(null);
    const [dataToView, setDataToView] = useState(null);
    const [formattedData, setFormattedData] = useState(null);
    const [originalFormattedData, setOriginFormattedData] = useState(null);
    const [issueOptions, setIssueOptions] = useState([]);
    const [roomOptions, setRoomOptions] = useState([]);
    const [selectedIssues, setSelectedIssues] = useState([]);
    const [selectedRooms, setSelectedRooms] = useState([]);

    //ui handlers
    const [isEdit, setIsEdit] = useState(false);
    const [isView, setIsView] = useState(false);

    const { register, watch, setValue, formState: { errors } } = useForm();

    //open edit modal
    const openEditModal = () => {
        setIsEdit(true);
    };

    //close edit modal
    const closeEditModal = () => {
        setIsEdit(false);
    };

    //open view modal
    const openViewModal = () => {
        setIsView(true);
    };

    //close view modal
    const closeViewModal = () => {
        setIsView(false);
    };

    const handleIssueChange = (event) => {
        setSelectedIssues(event.target.value);
        filterData(originalFormattedData, event.target.value, selectedRooms);
    };

    const handleRoomChange = (event) => {
        setSelectedRooms(event.target.value);
        filterData(originalFormattedData, selectedIssues, event.target.value);
    };


    const filterData = (data, selectedIssues, selectedRooms) => {
        const FILTERED_DATA = Object.keys(data).reduce((acc, key) => {
            const filteredItems = data[key].filter(item => {
                const issueName = item.bookingissueIssuetype.issuetypeSubcategory.name;
                const roomName = item.bookingissueRoomtype.name;

                const issueMatch = selectedIssues.length === 0 || selectedIssues.includes(issueName);
                const roomMatch = selectedRooms.length === 0 || selectedRooms.includes(roomName);

                if (selectedIssues.length > 0 && selectedRooms.length > 0) {
                    return issueMatch && roomMatch;
                }

                else if (selectedIssues.length > 0 && selectedRooms.length === 0) {
                    return issueMatch;
                }

                else if (selectedIssues.length === 0 && selectedRooms.length > 0) {
                    return roomMatch;
                }

                else {
                    return true;
                }

            });

            if (filteredItems.length > 0) {
                acc[key] = filteredItems;
            }
            return acc;
        }, {});

        setFormattedData(FILTERED_DATA)
    };

    const groupDataBySubcategory = () => {
        const grouped = {};

        galleryData.forEach(item => {
            // Check if bookingissueIssuetype and its subcategory name exist
            const key = item.bookingissueIssuetype?.issuetypeSubcategory?.name;
            if (key) {
                // If the key does not exist in the grouped object, initialize it as an array
                if (!grouped[key]) {
                    grouped[key] = [];
                }
                // Push the current item to the relevant array
                grouped[key].push(item);
            }
        });

        setFormattedData(grouped);
        setOriginFormattedData(grouped);
    };

    const makeMenuItems = () => {

        // Extract unique issuetypeSubcategory.name values
        const uniqueIssue = Array.from(
            new Set(galleryData.map(item => item.bookingissueIssuetype.issuetypeSubcategory.name))
        );

        // Extract unique bookingissueRoomtype.name values
        const uniqueRooms = Array.from(
            new Set(galleryData.map(item => item.bookingissueRoomtype.name))
        );

        setIssueOptions(uniqueIssue);
        setRoomOptions(uniqueRooms);
    };

    useEffect(() => {

        if (galleryData.length > 0) {
            groupDataBySubcategory();
            makeMenuItems();
        }


    }, [galleryData]);

    return (
        <Box sx={{ display: 'flex' }}>
            <div className="row full-width">

                <div className="col-md-12 mb-4">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <FormControl fullWidth>
                                <InputLabel id="issueLabel">All {navName} </InputLabel>
                                <Select
                                    labelId="issueLabel"
                                    id="issueLabelSelect"
                                    value={selectedIssues}
                                    label={`All ${navName} `}
                                    multiple
                                    onChange={handleIssueChange}
                                >
                                    {issueOptions.map((el) => (
                                        <MenuItem value={el} key={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col-md-4">
                            <FormControl fullWidth>
                                <InputLabel id="roomLabel">All Rooms </InputLabel>
                                <Select
                                    labelId="roomLabel"
                                    id="roomLabelSelect"
                                    value={selectedRooms}
                                    label={`All Rooms `}
                                    multiple
                                    onChange={handleRoomChange}
                                >
                                    {roomOptions.map((el) => (
                                        <MenuItem value={el} key={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>

                {galleryData.length > 0 && formattedData &&

                    // Group the issues based on their subcategory name
                    Object.keys(formattedData).map((subcategoryName) => (
                        <>
                            <div className="row">
                                <h3>
                                    {subcategoryName}
                                </h3>
                            </div>

                            {formattedData[subcategoryName].map((issue) => (
                                <div className="col-md-3 mb-5 mt-2" key={issue.id}>
                                    <Card sx={{ width: 330, border: `0.2px solid rgb(0, 148, 64)` }}>
                                        {/* Wrap only the image with the anchor tag */}
                                        <a href={issue.bookingissueBookingissueimage[0]?.issueImageUrl} target="_blank" rel="noopener noreferrer">
                                            <CardMedia
                                                component="img"
                                                height="250"
                                                image={issue.bookingissueBookingissueimage[0]?.issueImageUrl || "/static/images/cards/default.jpg"}
                                                alt="Image Not Available"
                                                sx={{ objectFit: 'cover', cursor: 'pointer' }} // Change cursor to pointer
                                            />
                                        </a>
                                        <CardContent>
                                            <Typography variant="body2">
                                                Issue: <b>&nbsp; {issue.bookingissueIssuetype.name}</b>
                                            </Typography>

                                            <Typography variant="body2" className='mt-2'>
                                                Status: <b>&nbsp; <mark>{issue.status}</mark> &nbsp;
                                                    {issue.status === "Passed" && (
                                                        <FontAwesomeIcon icon="fa-solid fa-circle-check" style={{ color: "green" }} />
                                                    )}
                                                    {issue.status === "Failed" && (
                                                        <FontAwesomeIcon icon="fa-solid fa-circle-xmark" style={{ color: "red" }} />
                                                    )}
                                                    {issue.status !== "Failed" && issue.status !== "Passed" && (
                                                        <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" style={{ color: "#009440" }} />
                                                    )}
                                                </b>
                                            </Typography>

                                            <Typography variant="body2" className='mt-2'>
                                                Room Category: <b>&nbsp; {issue.bookingissueRoomtype.name}</b>
                                            </Typography>

                                            <Typography variant="body2" className='mt-2'>
                                                Room Subcateogry: <b>&nbsp; {issue.bookingissueChildRoomtype.name}</b>
                                            </Typography>

                                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} className='mt-3'>

                                                <Button
                                                    onClick={() => {
                                                        openViewModal();
                                                        setDataToView(issue);
                                                    }}
                                                    variant="outlined"
                                                    size="small"
                                                >
                                                    See Details
                                                </Button>

                                                {userdata.roleId != CLIENT_ROLE_ID &&
                                                    <Button
                                                        onClick={() => {
                                                            openEditModal();
                                                            setDataToEdit(issue);
                                                        }}
                                                        variant="outlined"
                                                    >
                                                        Edit
                                                    </Button>
                                                }

                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            ))}
                        </>
                    ))
                }
            </div>

            {isEdit &&
                <UpdateViewModal handleCancelEvent={closeEditModal} isShowNotes={false} dataToEdit={dataToEdit} />
            }

            {isView &&
                <UpdateViewModal handleCancelEvent={closeViewModal} isShowNotes={true} dataToView={dataToView} />
            }
        </Box >
    );
}

export default ReportGallery;
