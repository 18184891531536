// @ts-nocheck

import { useTranslation } from "react-i18next";
import '../../../../../index.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from "@fortawesome/free-solid-svg-icons";
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import AlertM from '../../../../Helpers/AlertM/AlertM';
import { Button, Select, InputLabel, MenuItem } from "@mui/material";
import { useStyles } from './Styles';
import { useState, useRef } from 'react';
import LoadingButton from "@mui/lab/LoadingButton";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { FormControl, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect } from 'react';
import { patch } from "../../../../../Services/GenericApiService";
import { useDispatch } from "react-redux";
import { setRefreshFlag } from "../../../../../Global/Actions";
import { ISSUE_STATUS } from "../../../../../Constants/appConstants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@mui/material/Typography';

// font awesome stuff
const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

function UpdateViewModal({ handleCancelEvent, isShowNotes = false, dataToEdit = null, dataToView = null }) {

    // child ref for alert
    const notifications = useRef();

    // css for module
    const classes = useStyles();

    // module(s) for api
    const moduleBookingIssue = 'bookingissue';

    // translation
    const { t } = useTranslation();

    const dispatch = useDispatch();

    // form data
    const { register, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm(); // watch is used to get the value e.g watch("name")

    //data vars
    const [imageNotes, setImageNotes] = useState("");
    const [issueDescription, setIssueDescription] = useState("");
    const [issueStatus, setIssueStatus] = useState('');

    // ui handlers
    const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(false);

    // form methods
    const onSubmit = () => {
        let payload = {
            notes: imageNotes,
            issueDescription: issueDescription,
            bookingIssueImageId: dataToEdit.bookingissueBookingissueimage[0].id,
            status: issueStatus
        }

        handleEditEvent(payload);
    }

    const handleIssueStatus = (event) => {
        setIssueStatus(event.target.value);
    };

    // handle edit record event
    const handleEditEvent = (_formData: any) => {
        setLoading(true); // show loader
        patch(moduleBookingIssue + '/bookingImage', dataToEdit.id, _formData)
            .then(async (_res) => {

                setLoading(false);
                dispatch(setRefreshFlag(true))
                handleCancelEvent();
            })
            .catch(_err => {
                console.log(_err)
                setLoading(false);

            })
    }

    // handle cancel event
    const handleCancel = () => {
        handleClose();
        handleCancelEvent();
    }

    // hooks
    useEffect(() => {
        if (!isShowNotes) {

            setIssueStatus(dataToEdit.status);
            setImageNotes(dataToEdit.bookingissueBookingissueimage[0].notes);
            setIssueDescription(dataToEdit.issueDescription);

        }
    }, []);

    return (
        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleCancel}
                closeAfterTransition
                onBackdropClick={handleCancel}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box className={classes.modal}>
                        <Box sx={{ display: 'flex' }}>
                            <Card sx={{ width: '75vw', maxHeight: '90vh', overflowY: 'auto' }}>

                                {/* ------------------- if show notes is false then show edit form ------------------- */}

                                {!isShowNotes &&
                                    <>
                                        <form className="row px-1 px-md-0" onSubmit={handleSubmit(onSubmit)}>

                                            <CardContent className="px-5">
                                                <div className="row">

                                                    <div className="col-md-12 my-2">
                                                        {/* Form name */}
                                                        <h3 className="pb-2">
                                                            Update Booking Issue Data
                                                        </h3>

                                                    </div>

                                                    {/* Issue */}
                                                    <div className="col-md-6 mt-4">
                                                        <FormControl fullWidth>
                                                            <TextField id="issueInput"
                                                                value={dataToEdit.bookingissueIssuetype.name}
                                                                disabled
                                                                label={'Issue'}
                                                                type={"text"}
                                                                variant="outlined"
                                                                className="full-width" />
                                                        </FormControl>
                                                    </div>

                                                    {/* Parent Room */}
                                                    <div className="col-md-6 mt-4">
                                                        <FormControl fullWidth>
                                                            <TextField id="roomCategoryInput"
                                                                value={dataToEdit.bookingissueRoomtype.name}
                                                                disabled
                                                                label={'Room Category'}
                                                                type={"text"}
                                                                variant="outlined"
                                                                className="full-width" />
                                                        </FormControl>
                                                    </div>

                                                    {/* Child Room */}
                                                    <div className="col-md-6 mt-4">
                                                        <FormControl fullWidth>
                                                            <TextField id="roomSybCategoryInput"
                                                                value={dataToEdit.bookingissueChildRoomtype.name}
                                                                disabled
                                                                label={'Room Subcategory'}
                                                                type={"text"}
                                                                variant="outlined"
                                                                className="full-width" />
                                                        </FormControl>
                                                    </div>

                                                    {/* Status */}
                                                    <div className="col-md-6 mt-4">
                                                        <FormControl fullWidth>
                                                            <InputLabel id="select-label">Issue Status</InputLabel>
                                                            <Select
                                                                labelId="select-label"
                                                                id="status-select"
                                                                value={issueStatus}
                                                                onChange={handleIssueStatus}
                                                                label="Issue Status"
                                                            >
                                                                {ISSUE_STATUS.map((item) => (
                                                                    <MenuItem key={item.value} value={item.value}>
                                                                        {item.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                    {/* Isssue Decription */}
                                                    <div className="col-md-6 mt-4">
                                                        <TextField
                                                            id="issueDescription"
                                                            label="Issue Description"
                                                            value={issueDescription}
                                                            onChange={e => setIssueDescription(e.target.value)}
                                                            multiline
                                                            className={'full-width'}
                                                            minRows={5}

                                                        />
                                                    </div>

                                                    {/* Image Notes */}
                                                    <div className="col-md-6 mt-4">
                                                        <TextField
                                                            id="imageNotes"
                                                            label="Image Notes"
                                                            value={imageNotes}
                                                            onChange={e => setImageNotes(e.target.value)}
                                                            multiline
                                                            className={'full-width'}
                                                            minRows={5}

                                                        />
                                                    </div>

                                                </div>

                                            </CardContent>
                                            <CardActions>
                                                <div className="pb-4 pt-2 px-md-5 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                                                    {/* Cancel Button */}
                                                    <Button variant="outlined"
                                                        onClick={handleCancel}>
                                                        {t('common.cancel')}
                                                    </Button>

                                                    &nbsp;&nbsp;

                                                    {/* Update Button */}
                                                    <LoadingButton loading={loading}
                                                        type="submit"
                                                        variant="contained"
                                                        className="bg-custom-gradient">
                                                        {t('common.edit')}
                                                    </LoadingButton>
                                                    &nbsp;
                                                </div>
                                            </CardActions>
                                        </form>
                                    </>
                                }


                                {/* -------------------Show Details-------------------------- */}
                                {isShowNotes &&
                                    <>
                                        <CardContent>
                                            <div className="p-4">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {/* Form name */}
                                                        <h3>
                                                            View Booking Issue Data
                                                        </h3>
                                                    </div>
                                                </div>

                                                <hr />

                                                <div className="row mt-4">
                                                    <div className="col-md-6">
                                                        <a
                                                            href={dataToView.bookingissueBookingissueimage[0]?.issueImageUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img
                                                                src={dataToView.bookingissueBookingissueimage[0]?.issueImageUrl || "/static/images/cards/default.jpg"}
                                                                alt="Image Not Available"
                                                                style={{ objectFit: 'cover', cursor: 'pointer', width: '100%' }} // Adjust styles as needed
                                                            />
                                                        </a>

                                                    </div>

                                                    <div className="col-md-6">

                                                        <Typography variant="body1">
                                                            <b>Issue: </b>&nbsp; {dataToView.bookingissueIssuetype.name}
                                                        </Typography>

                                                        <Typography variant="body1" className='mt-2'>
                                                            <b>Status: </b>&nbsp; <mark>{dataToView.status}</mark> &nbsp;
                                                            {dataToView.status === "Passed" && (
                                                                <FontAwesomeIcon icon="fa-solid fa-circle-check" style={{ color: "green" }} />
                                                            )}
                                                            {dataToView.status === "Failed" && (
                                                                <FontAwesomeIcon icon="fa-solid fa-circle-xmark" style={{ color: "red" }} />
                                                            )}
                                                            {dataToView.status !== "Failed" && dataToView.status !== "Passed" && (
                                                                <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" style={{ color: "#009440" }} />
                                                            )}

                                                        </Typography>

                                                        <Typography variant="body1" className='mt-2'>
                                                            <b>Severity: </b>&nbsp; {dataToView.severity}
                                                        </Typography>

                                                        <Typography variant="body1" className='mt-2'>
                                                            <b>Room Category: </b>&nbsp; {dataToView.bookingissueRoomtype.name}
                                                        </Typography>

                                                        <Typography variant="body1" className='mt-2'>
                                                            <b>Room Subcateogry: </b>&nbsp; {dataToView.bookingissueChildRoomtype.name}
                                                        </Typography>

                                                        <Typography variant="body1" className='mt-2'>
                                                            <b>Issue Description: </b>&nbsp; {dataToView.issueDescription}
                                                        </Typography>

                                                        <Typography variant="body1" className='mt-2'>
                                                            <b>Image Notes: </b>&nbsp; {dataToView.bookingissueBookingissueimage[0].notes}
                                                        </Typography>

                                                    </div>
                                                </div>
                                            </div>
                                        </CardContent>
                                        <CardActions>
                                            <div className="pb-4 pt-2 px-md-4 px-2 " style={{ width: '100%', textAlign: 'right' }}>

                                                {/* Cancel Button */}
                                                <Button variant="outlined"
                                                    onClick={handleCancel}>
                                                    {t('common.cancel')}
                                                </Button>
                        
                                            </div>
                                        </CardActions>
                                    </>
                                }
                            </Card>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default UpdateViewModal;
